import * as React from "react"
import { lazy, Suspense } from "react"
import { StaticQuery, graphql } from "gatsby"
import { useDispatch } from "react-redux"
import { getHomepageQueryData } from "../store/actions/homepage"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomePage from "../components/HomePage"

const IndexPage = () => {
  const dispatch = useDispatch()

  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZTo2") {
          homepage_contents {
            banner {
              image {
                title
                sourceUrl
              }
              buttonText {
                en
                sv
              }
              buttonText1 {
                en
                sv
              }
              highlightedText {
                sv
                en
              }
              preText1 {
                en
                sv
              }
              preText2 {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
              buttonText1 {
                en
                sv
              }
              highlightedText {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
              titleGroups {
              text
            }
            }
            joinWebduraSection {
              subtitle {
                en
                sv
              }
              title {
                en
                sv
              }
              description {
                en
                sv
              }
              list {
                description {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
            }
            solutionsSection {
              description {
                en
                sv
              }
              list {
                icon {
                  sourceUrl
                  title
                }
                text {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              subtitle {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            caseStudies {
              sliders {
                flag {
                  title
                  sourceUrl
                }
                modelImage {
                  title
                  sourceUrl
                }
                stacks {
                  img {
                    title
                    sourceUrl
                  }
                }
                subtitleOne {
                  en
                  sv
                }
                subtitleTwo {
                  en
                  sv
                }
                subtitleThree {
                  en
                  sv
                }
                textOne {
                  en
                  sv
                }
                textTwo {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              subtitle {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            blog {
              text {
                en
                sv
              }
              title {
                en
                sv
              }
              blogbuttontext {
                en
                sv
              }
            }
            specialTeam {
              buttontext {
                en
                sv
              }
              clientTitle {
                en
                sv
              }
              clients {
                img {
                  sourceUrl
                  title
                }
              }
              description {
                en
                sv
              }
              mainDescription {
                en
                sv
              }
              mainTitle {
                en
                sv
              }
              question {
                en
                sv
              }
              solutions {
                answer {
                  en
                  sv
                }
              }
              subtitle {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            avtivity {
            description {
              en
              sv
            }
            link {
              pageLink
              text {
                en
                sv
              }
            }
            rules {
              title {
                en
                sv
              }
              image {
                sourceUrl
              }
              description {
              en
              sv
            }
            }
            subtitle {
              en
              sv
            }
            title {
              en
              sv
            }
          }
          }
        }
      }
    }
  `


  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch(getHomepageQueryData())
        dispatch({ type: "HOME_PAGE_QUERY_SUCCESS", payload: wdwordpress })
        return (
          <>
            <Layout>
              <Seo
                title="A Full Spectrum Digtal Agency | Webdura"
                pageId="cGFnZTo2"
              />
              <HomePage />
            </Layout>
          </>
        )
      }}
    />
  )
}

export default IndexPage
