import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Modal from "react-bootstrap/Modal"

import CommonModal from "../Common/Modal"

const Banner = () => {
  const language = useSelector(state => state.global.language)
  const banner = useSelector(
    state => state?.homepage?.page?.homepage_contents?.banner
  )

  const [currentIndex, setIndex] = useState(0)


  const words = banner.titleGroups.map(group => group.text)

  useEffect(() => {
    let interval = null

    if (interval) {
      clearInterval(interval)
    }
    interval = setInterval(() => {
      setIndex(currentIndex => currentIndex >= words.length - 1 ? 0 : currentIndex + 1)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const [modal, setModal] = useState(false)
  const closeModal = () => {
    setModal(false)
  }


  const [first, second] = banner?.title?.[language].split('UTVECKLARE')


  return (
    <div className="banner homebanner">
      <div className="container">
        <div className="banner_top"></div>
        <div className="banner_txt">
          <div>
            <div className="light img-fluid">
              <img
                src={banner.image.sourceUrl}
                alt={banner.image.title ? banner.image.title : "Home-Avatar"}
              />
            </div>
            <h1 className="split-text landing-heading">
              {first}<span key={currentIndex} className="split-text-word">{words[currentIndex]}</span>
              <span className="talents">{second}</span>
            </h1>
            <p className="split-text">
              <span className="banner_txtclr">
                {banner?.highlightedText?.[language]}
              </span>
              {banner?.text?.[language]}
            </p>
            <button
              type="button"
              onClick={() => setModal(true)}
              data-toggle="modal"
              data-target="#basicModal1"
              className="common_cls banhide"
            >
              {banner?.buttonText?.[language]}
              <i className="arrow" />
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => setModal(true)}
              href="#"
              data-toggle="modal"
              data-target="#basicModal1"
              className="common_cls ban"
            >
              {banner?.buttonText?.[language]}
              <i className="arrow" />
            </button>
          </div>
        </div>
      </div>

      <Modal
        scrollable={true}
        show={modal}
        centered
        onHide={() => closeModal()}
      >
        <CommonModal closeModal={closeModal} />
      </Modal>
    </div>
  )
}
export default Banner
