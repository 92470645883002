import React, { useState } from "react"
import Slider from "react-slick"
import Modal from "react-bootstrap/Modal"
import { useSelector } from "react-redux"
import { TestimonialsData } from "../../queries/common"
import CustomModal from "./Modal"
import { graphql } from "gatsby"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import VideoPlayer from "./VideoPlayer"

const Testimonials = () => {
  const language = useSelector(state => state.global.language)

  const { testimonials } = TestimonialsData()
  const [modal, setModal] = useState(false)
  const [index, setIndex] = useState(0)

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveWidth: true,
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 319,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      setIndex(newIndex)
    }
  }

  return (
    <LazyLoadComponent>
      <section className="btm_black">
        <div className="container">
          <div className="btm_part">

            <h2 className="split-text">
              {testimonials.testimonialTitle[language]}
              {/* {testimonials?.clienttitle?.[language]} */}
            </h2>

            <div className="wdt_p">
              <p>{testimonials?.text?.[language]}</p>
              {/* <a onClick={() => setModal(true)} className="indbtn btm_partbtn">
                {testimonials?.anchorText?.[language]}
                <i className="arrow" />
              </a> */}
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial_section">
        <div className="container">
          <div className="testimonial text-center">
            {/* <h2 className="testimonial-title pb-5">
              {testimonials.testimonialTitle[language]}
            </h2> */}
            <Slider className="testimonial_slider m-0" {...settings}>
              {testimonials?.list.map((tm, key) => (
                <div key={tm.img.sourceUrl}>
                  <img
                    src={tm.img.sourceUrl}
                    alt="img"
                    className="d-block m-auto"
                  />
                  <h3 className="m-0">{tm.name?.[language]}</h3>
                  <h4 className="m-0 font-weight-bold">
                    {tm.position?.[language]}
                  </h4>
                  <div className="testimonial-video-wrap-container" >

                        {
                          tm.video ?
                            <VideoPlayer
                              activeIndex={index}
                              itemIndex={key}
                              className="testimonial-video-wrap" url={tm.video.mediaItemUrl} />
                            : <p>{tm.comment?.[language]}</p>
                        }

                      </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <Modal show={modal} centered onHide={() => setModal(false)}>
          <CustomModal
            closeModal={() => {
              setModal(false)
            }}
          />
        </Modal>
      </section>
    </LazyLoadComponent>
  )
}

export default Testimonials
