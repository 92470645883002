import { Link } from 'gatsby'
import React from 'react'
import { useSelector } from 'react-redux'
import { ActivityImageGrid } from '../HomePage/AvtivityGrid'

function Activities() {
    const language = useSelector(state => state.global.language)

    const activityData = useSelector(
        state => state?.homepage?.page?.homepage_contents?.avtivity
    )

    const [rule1, rule2, rule3, rule4, rule5] = activityData?.rules

    return (
        <div className='activitis-container'>
            <div className="lftbrdr-activity">
                <h6>{activityData?.title?.[language]}</h6>
                <h2>{activityData?.subtitle?.[language]}</h2>
                <p>{activityData?.description?.[language]}</p>
                <Link className="indbtn btm_partbtn" to={`${activityData?.link?.pageLink}`}>
                    {activityData?.link?.text?.[language]}
                    <i className="arrow" />
                </Link>
                <div className='act_grid_cont'>

                    <ActivityImageGrid>
                        <figure className='act_grid_item fig1'>
                            <img src={rule1?.image?.sourceUrl}></img>
                            <div className='stick_rule'>
                                <div className='rule_box rule_box_red'>
                                    <span className='rule_box_red_title'>{rule1?.title?.[language]}</span>
                                    <span className='rule_box_red_caption'>{rule1?.description?.[language]}</span>
                                </div>
                            </div>
                        </figure>

                        <figure className='act_grid_item fig2'>
                            <div className='rule_box rule_box_white'>
                                <span className='rule_box_white_title'>{rule2?.title?.[language]}</span>
                                <span className='rule_box_white_caption'>{rule2?.description?.[language]}</span>
                            </div>
                        </figure>

                        <figure className='act_grid_item fig3'>
                            <img src={rule2?.image?.sourceUrl}></img>
                        </figure>

                        <figure className='act_grid_item fig4'>
                            <img src={rule3?.image?.sourceUrl}></img>
                        </figure>

                        <figure className='act_grid_item fig5'>
                            <div className='rule_box rule_box_red'>
                                <span className='rule_box_red_title'>{rule3?.title?.[language]}</span>
                                <span className='rule_box_red_caption'>{rule3?.description?.[language]}</span>
                            </div>
                        </figure>

                        <figure className='act_grid_item fig6'>
                            <img src={rule4?.image?.sourceUrl}></img>
                        </figure>
                        <figure className='act_grid_item fig7'>
                            <div className='rule_box rule_box_white'>
                                <span className='rule_box_white_title'>{rule4?.title?.[language]}</span>
                                <span className='rule_box_white_caption'>{rule4?.description?.[language]}</span>
                            </div>
                        </figure>
                        <figure className='act_grid_item fig8'>
                            <img src={rule5?.image?.sourceUrl}></img>
                        </figure>
                    </ActivityImageGrid>

                </div>
            </div>


        </div>
    )
}

export default Activities