import React from "react"
import Slider from "react-slick"
import { useSelector } from "react-redux"
import { CaseStudy } from "../../queries/casestudy"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ClientBox = () => {
  const language = useSelector(state => state.global?.language)
  // const data = useSelector(
  //   state => state?.homepage?.page?.homepage_contents?.caseStudies
  // )
  const { caseStudies } = CaseStudy()

  let data = caseStudies

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    adaptiveWidth: true,
    adaptiveHeight: true,
    centerMode: false,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerPadding: "20%",
    responsive: [
      {
        // breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          // centerPadding: "3%",
        },
      },
      {
        breakpoint: 319,
        settings: {
          slidesToShow: 1,
          // centerPadding: "3%",
        },
      },
    ],
  }

  const settingsMob = {
    autoplay: false,
    dots: false,
    loop: true,
    infinite: true,
    arrows: true,
  }

  if (data) {
    return (
      <>
        {" "}
        <section className="project_slider">
          <div className="phoneclr">
            <div className="container">
              <div className="row text_side">
                <div className="txt_block">
                  <div className="lftbrdr-cnew">
                    <h6 style={{ color: "#eb2214" }}>
                      {data.subtitle?.[language]}
                    </h6>
                    <h2 style={{ color: "#000" }}>{data.title?.[language]}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container d-none d-sm-none d-md-block">
              <Slider className="center project_list mb-0" {...settings}>
                {data.sliders.map(slider => (
                  <div className="casestudy_slider">
                    <div className="casestudy-left">
                      <h2>
                        {slider.title[language]}
                        <img
                          style={{
                            width: 40,
                            display: "inline",
                            marginLeft: 20,
                            paddingTop: 20,
                          }}
                          src={slider.flag.sourceUrl}
                        />
                      </h2>
                      <strong style={{ fontStyle: "italic" }}>
                        {slider.subtitleOne[language]}
                      </strong>
                      <p>{slider.textOne[language]}</p>
                      <strong style={{ fontStyle: "italic" }}>
                        {slider.subtitleTwo[language]}
                      </strong>
                      <ul style={{ marginLeft: 0 }}>
                        {slider.textTwo.map(ele => (
                          <li style={{ display: "flex", lineHeight: "20px" }}>
                            <i
                              className="bi bi-dash"
                              style={{
                                color: "#ef4035",
                                marginRight: 10,
                                fontSize: 23,
                              }}
                            />
                            {ele[language]}
                          </li>
                        ))}
                      </ul>
                      <div className="flex casestudy_stacks">
                        <div className="border-right">
                          <h3>{slider.subtitleThree[language]}</h3>
                        </div>
                        {slider.stacks.map(ele => (
                          <img
                            style={{ marginLeft: 20 }}
                            src={ele.img.sourceUrl}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="casestudy-right">
                      <img src={slider.modelImage.sourceUrl} />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>

            {/* mobile */}
            <div className="container d-sm-block d-md-none">
              <Slider className="center project_list mb-0" {...settingsMob}>
                {data.sliders.map(slider => (
                  <div className="casestudy_slider">
                    <div className="casestudy-left">
                      <h2>
                        {slider.title[language]}
                        {/* <img
                          style={{
                            width: 40,
                            display: "inline",
                            marginLeft: 20,
                            paddingTop: 20,
                          }}
                          src={slider.flag.sourceUrl}
                        /> */}
                      </h2>
                      <strong style={{ fontStyle: "italic" }}>
                        {slider.subtitleOne[language]}
                      </strong>
                      <p>{slider.textOne[language]}</p>
                      <strong style={{ fontStyle: "italic" }}>
                        {slider.subtitleTwo[language]}
                      </strong>
                      <ul>
                        {slider.textTwo.map(ele => (
                          <li style={{ display: "flex", lineHeight: "20px" }}>
                            <i
                              className="bi bi-dash"
                              style={{
                                color: "#ef4035",
                                marginRight: 10,
                                fontSize: 23,
                              }}
                            />
                            {ele[language]}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="casestudy-right">
                      <img src={slider.modelImage.sourceUrl} />
                    </div>
                    <div
                      className="flex casestudy_stacks"
                      style={{ marginTop: 15 }}
                    >
                      <div className="border-right">
                        <h3>{slider.subtitleThree[language]}</h3>
                      </div>
                      <div style={{ display: "flex", width: 25 }}>
                        {slider.stacks.map(ele => (
                          <img
                            style={{ marginLeft: 15 }}
                            src={ele.img.sourceUrl}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
      </>
    )
  } else return null
}

export default ClientBox
