import React from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { EnglishBlogs } from "../../queries/blogs"
import { SwedishBlogs } from "../../queries/swedishBlogs"

const Blog = () => {
  const language = useSelector(state => state.global.language)

  const { page: { homepage_contents }, posts } = language === 'en' ? EnglishBlogs() : SwedishBlogs()
  const blogPageData = homepage_contents?.blog

  return (
    <section className="blog_news">
      <div className="container">
        <h2 className="brdrhtwo">{blogPageData?.text?.[language]}</h2>
        <h3 className="blog_newshead">{blogPageData?.title?.[language]}</h3>
        <div className="rowblog">
          {posts?.nodes?.map(item => (
            <Link
              to={`/blogs/${item.slug}`}
              className="blogbox"
              key={item.slug}
            >
              <LazyLoadImage
                effect="blur"
                src={item.single_blog.featuredImages.thumbnailImage.sourceUrl}
                alt="img"
                className="img-fluid"
              />
              <div className="blogbox_inner">
                <h6>{item?.categories?.nodes[0]?.name}</h6>
                <h3 dangerouslySetInnerHTML={{ __html: item?.title }} />
                <span>{moment(item?.date).format("DD MMM yyyy")}</span>
              </div>
            </Link>
          ))}
        </div>
        <Link className="blog_btn" to="/blogs">
          {blogPageData?.blogbuttontext[language]}
          <i className="arrow" />
        </Link>
      </div>
    </section>
  )
}

export default Blog
